.App {
  background-color: black;
}

.App-header {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(8px + 2vmin);
  color: white;
  margin: 1em;
  top: 0;
  position: absolute;
  z-index: 1;
}

.App-footer {
  text-align: center;
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(2px + 2vmin);
  color: white;
  margin: 1em;
  position: absolute;
  bottom: 0;
  z-index: 1;
}

strong {
	color: white;
  }

.title,
.subtitle,
.Contact-adress{
  color: white;
}

.App-link {
  color: #0190eb;
}

a {
  color: #0190eb;
}

.App-logo {
  width: 2.5em;
  margin: 0 0 -1em 1.5em;

}

.Patrik-img {
  border-radius: 50%;
  zoom: 1.5;
}

.Loggo-img {
  zoom: 0.2;
}

.text-right {
 text-align: right;
}

.text-left {
  text-align: left;
}

.card-content {
 text-align: center;
 color: white;
 font-size: calc(5px + 2vmin);
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.Linkedin-img {
  width: 20px;
  margin: 10px;
  vertical-align: middle;
}

.Header-text {
  margin-top: 0;
  margin-bottom: 5px;
}

.Contact-norehill {
  max-height: 600px;
  max-width: 900px;
  width: 100%;
  height: 100%;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

/* 
.Contact-container {
  background-color: white;
  color: black;
  min-height: 100vh;
}

.Contact-header {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);

}

.Contact-norehill {
  margin: 10px;
  border-radius: 10px;
  max-height: 600px;
  max-width: 900px;
}

.Contact-adress {
  display:flex;
  flex-direction: column wrap;
  align-items: center;
 justify-content: center;
} */



